.updates_data {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5vmin;
}

button {
  min-width: 20vmin;
  text-align: center;
  min-height: 5vmin;
}

.updates_table {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  margin-top: 5vmin;
}

.login_main {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);;
  width: 100vmin;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vmin;
  padding: 10vmin;
  height:  65vmin;
}
.main_logo {
  width: 50vmin;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  height: 10vmin;
  justify-content: center;
  padding: 1vmin;
  margin-top: 3vmin;
}
.submit_button {
  width: 60vmin;
  background-color: black !important;
  color: white;
  border: none !important;
}

.form_input {
  margin-bottom: 2vmin;
  margin-top: 1vmin;
}

.alert_success {
  width: 30vmin;
  margin: auto;
  position: absolute;
  z-index: 99;
}

.events_container {
  position: relative;
  z-index: 0;
}

.custom_button {
  background-color: transparent;
  border: 2px solid rgb(168, 0, 0);
  color: black;
  width: 20vmin;
}

.custom_button:hover {
  background-color: rgb(168, 0, 0);
  color: white;
  width: 20vmin;
}
.logo {
  width: 50vmin;
  height: 8vmin;
}